import React from "react"
import Services from "../services"

// Background Image component
import Background from "../backgroundImage"

const Banner = ({ data: { bannerTitle, bannerSubtitle }, customNav }) => {
  return (
    <Background className={customNav ? "pt-8 md:pt-16" : ""}>
      <div className="max-w-6xl px-4 mx-auto md:px-8 xl:px-0">
        <div className="py-12 font-bold text-white siteFont">
          <h1 className="text-3xl uppercase md:text-5xl ">{bannerTitle}</h1>
          <h3 className="text-xl md:text-2xl">{bannerSubtitle}</h3>
        </div>
        <Services servicePage />
        <hr className="hidden my-20 md:block" />
      </div>
    </Background>
  )
}

export default Banner
