import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

const MobileView = ({ service }) => {
  const {
    id,
    title,
    description,
    image,
    imageHover,
    btnText,
    btnLink,
  } = service
  return (
    <div
      className="flex flex-col items-center justify-center w-full text-center serviceItem sm:w-1/2 lg:w-1/3 md:px-2"
      key={id}
    >
      <div className="relative w-full h-full">
        <img
          src={image.file.url}
          alt={image.title}
          className="w-11/12 h-full mx-auto image"
        />
        <object
          type="image/svg+xml"
          data={imageHover.file.url}
          className="w-11/12 h-full mx-auto cursor-pointer imageHover"
        >
          {imageHover.title}
        </object>
      </div>
      <h2 className="text-xl font-bold text-white md:text-3xl siteFont">
        {title}
      </h2>
      <p className="w-full pt-2 text-greyTxt">{description}</p>
      <Link
        to={`/services/${btnLink}`}
        className="inline-block px-8 py-2 mt-4 mb-12 text-sm font-bold uppercase border rounded-full blueBtnBg btn text-primary md:my-4 focus:outline-none siteFont"
      >
        {btnText} {">>>"}
      </Link>
    </div>
  )
}
const DesktopView = ({ service }) => {
  const {
    id,
    title,
    description,
    image,
    imageHover,
    btnText,
    btnLink,
  } = service

  return (
    <Link
      className="relative flex flex-col items-center justify-center w-full text-center serviceItem sm:w-1/2 md:px-2"
      key={id}
      to={`/services/${btnLink}`}
    >
      <div className="relative z-10 w-full h-full">
        <img
          src={image.file.url}
          alt={image.title}
          className="w-11/12 h-full mx-auto image"
        />
        <object
          type="image/svg+xml"
          data={imageHover.file.url}
          className="w-full h-full mx-auto cursor-pointer imageHover"
        >
          {imageHover.title}
        </object>
      </div>
      <div className="test">
        <h2 className="text-xl font-bold text-white md:text-3xl siteFont">
          {title}
        </h2>
        <p className="w-full pt-2 text-greyTxt">{description}</p>
        <div className="inline-block px-8 py-2 mt-4 mb-12 text-sm font-bold uppercase border rounded-full blueBtnBg btn text-primary md:my-4 focus:outline-none siteFont">
          {btnText} {">>>"}
        </div>
      </div>
    </Link>
  )
}

const Services = ({ servicePage }) => {
  const data = useStaticQuery(graphql`
    query ServicesQuery {
      servicesList: contentfulHomePage {
        services {
          id
          title
          description
          btnText
          btnLink
          image {
            title
            file {
              url
            }
          }
          imageHover {
            title
            file {
              url
            }
          }
        }
      }
    }
  `)

  const { services } = data.servicesList
  return (
    <div className="serviceList">
      <div className="md:hidden">
        {services.map(service => {
          return <MobileView service={service} />
        })}
      </div>
      <div className="flex-wrap justify-around hidden w-full md:flex">
        {services.map(service => {
          return <DesktopView service={service} />
        })}
      </div>
    </div>
  )
}

export default Services
