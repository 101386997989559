import React, { useContext } from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Banner from "../../components/Services/banner"
import OurStory from "../../components/Services/ourStory"
import Slider from "../../components/slider"
import ContactUs from "../../components/contactUs"

// Context API data
import { AppContext } from "../../store/AppContext"

const ServicesPage = ({ data }) => {
  const globalData = useContext(AppContext)
  const {
    ogTitle,
    ogDescription,
    bannerTitle,
    bannerSubtitle,
    title,
    subtitle,
    description,
    section1Title,
    section1Description,
    section2Title,
    section2Description,
    specialtyTitle,
    specialtyImages,
  } = data.service

  const storyData = {
    title,
    subtitle,
    description,
    section1Title,
    section1Description,
    section2Title,
    section2Description,
    specialtyTitle,
    specialtyImages,
  }
  return (
    <Layout>
      <SEO title={ogTitle || "Services"} description={ogDescription} />
      <Banner
        data={{ bannerTitle, bannerSubtitle }}
        customNav={globalData?.state?.customNav}
      />
      <OurStory data={storyData} />
      <Slider blueBackground />
      <ContactUs />
    </Layout>
  )
}

export const query = graphql`
  query ServicePageQuery {
    service: contentfulServicesPage {
      ogTitle
      ogDescription
      bannerTitle
      bannerSubtitle
      title
      subtitle {
        json
      }
      description {
        json
      }
      section1Title
      section1Description {
        json
      }
      section2Title
      section2Description {
        json
      }
      specialtyTitle
      specialtyImages {
        title
        file {
          url
        }
      }
    }
  }
`

export default ServicesPage
