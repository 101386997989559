import React from "react"
import RichTextRenderer from "../RichTextRenderer"

const OurStory = ({
  data: {
    title,
    subtitle,
    description,
    section1Title,
    section1Description,
    section2Title,
    section2Description,
    specialtyTitle,
    specialtyImages,
  },
}) => (
  <div className="max-w-6xl mx-auto px-4 md:px-8 xl:px-0">
    <div className=" md:flex md:justify-between">
      <div className="md:w-3/5">
        <h2 className="text-white font-bold text-3xl md:text-4xl uppercase text-center md:text-left siteFont">
          {title}
        </h2>
        <div className="text-white font-semibold my-8 text-xl text-center md:text-left">
          <div>{RichTextRenderer(subtitle.json)}</div>
        </div>
        <div className="text-greyTxt md:text-lg">
          {RichTextRenderer(description.json)}
        </div>
      </div>
      <div className="text-white flex flex-wrap md:block my-8 md:my-0">
        {specialtyImages.map((item, index) => (
          <div
            key={index}
            className="w-1/2 md:w-full flex flex-col md:flex-row md:justify-start items-center text-center md:text-left mb-4"
          >
            <div className="w-1/3 md:w-16 md:mr-8">
              <img src={item.file.url} alt={item.title} />
            </div>
            <p className="font-bold text-lg md:text-xl siteFont">
              {specialtyTitle[index]}
            </p>
          </div>
        ))}
      </div>
    </div>

    <div className="my-8 md:my-20 md:flex md:justify-between">
      <div className="md:w-1/2 text-greyTxt mb-6 md:mb-0">
        <h3 className="text-white font-bold text-2xl my-4 siteFont">
          {section1Title}
        </h3>
        <div className="md:w-11/12 md:text-lg">
          {RichTextRenderer(section1Description.json)}
        </div>
      </div>
      <div className="md:w-1/2 text-greyTxt">
        <h3 className="text-white font-bold text-2xl my-4 siteFont">
          {section2Title}
        </h3>
        <div className="md:text-lg">
          {RichTextRenderer(section2Description.json)}
        </div>
      </div>
    </div>
  </div>
)

export default OurStory
